// Skills Icons
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react2.svg"
import jsIcon from "./images/javascript.svg"
import nodejs from "./images/nodejs.svg"
import postgres from "./images/postgres.svg"


// Social Icon
import githubIcon from "./images/github.svg"
import LinkedInIcon from "./images/linkedin.svg"
// import twitterIcon from "./images/twitter.svg"
import instagramIcon from "./images/instagram.svg"
import mongodb from "./images/mongodb.svg"




export const data =  {
  //   Header Details ---------------------
  name: "Claudia Susana Mazariegos ",
  headerTagline: ["Software Engineer"],

  //Contact Email
  contactEmail: "claudias.mazariegos@gmail.com",

  // Work Section ------------------------
  projects: [
    {
      id: 1, 
      title: "Allocations", 
      para:
        "Allocations is the fastest growing private markets platform that helps you to close more deals", 
      imageSrc:
        "https://res.cloudinary.com/techhire/image/upload/v1651274036/Screen_Shot_2022-04-29_at_4.13.31_PM_zedgqg.png",
      github: "none",
      linkedin: "https://www.linkedin.com/company/allocations/",
      url: "https://www.allocations.com/",
    },
    {
      id: 2, 
      title: "SkinCode()", 
      para:
        "An AI powered app designed to help you find your perfect foundation match.", 
      imageSrc:
        "https://res.cloudinary.com/techhire/image/upload/v1620417273/Screen_Shot_2021-04-28_at_10.13.53_AM_hpqsa7.png",
      github: "https://github.com/Foundation-App/skincode-fe",
      url: "https://skincode.app/",
    },
    {
      id: 3, 
      title: "Professional Portfolio", 
      para:
        "Editor portfolio with work,about,contact pages.", 
      imageSrc:
        "https://res.cloudinary.com/techhire/image/upload/v1620921048/Screen_Shot_2021-05-13_at_8.49.56_AM_kfohos.png",
      github: "https://github.com/cmazariegos44/matt-fe",
      url: "https://www.immattshapiro.com/",
    },
    {
      id: 4, 
      title: "Revolver Life", 
      para:
        "Book flights, 🏨 hotels & 🍽️ make reservations through your personalised AI travel companion", 
      imageSrc:
        "https://res.cloudinary.com/techhire/image/upload/v1617052165/revolver_iusp2o.png",
      url: "https://nomade4sdgs.org/",
    },

  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "I am a Tech enthusiast with a passion for understanding how software works. My knowledge in Programming, combined with a five year background in Account Management gives me the tools to explore further into how software can be used to solve business problems.",
  aboutParaTwo:
    "I attended Alchemy Code Lab and have ",



  aboutImage:
    "https://res.cloudinary.com/techhire/image/upload/c_scale,h_400,w_320/v1614459207/Claudia_Mazariegos_1_fmbfmt.jpg",

  //   End About Section ---------------------

  // Skills Section ---------------
  skills: [
    {
      id: 1,
      img: reactIcon,
      para:
        "React.js",
    },
    {
      id: 2,
      img: cssIcon,
      para:
        "CSS",
    },
    {
      id: 3,
      img: jsIcon,
      para:
        "Javascript",
    },
    {
      id: 4,
      img: nodejs,
      para:
        "Node.js",
    },
    {
      id: 5,
      img: postgres,
      para:
        "PostgreSQL",
    },
    {
      id: 6,
      img: mongodb,
      para:
        "MongoDB",
    },
  ],

  // End Skills Section --------------------------



  //   Contact Section --------------
  contactSubHeading: "Let's create your next experience together",
  social: [
    { img: githubIcon, 
      url: "https://github.com/cmazariegos44" 
    },
    // {
    //   img: twitterIcon,
    //   url: "https://twitter.com/cmazariegos44",
    // },
    {
      img: LinkedInIcon,
      url: "https://www.linkedin.com/in/claudia-mazariegos/",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/claudiamaza44/",
    },
  ],

}
