import styled from 'styled-components';

export const NavItem = styled.li`
  height: 50px;
  display: flex;
  list-style-type: none;
  align-items: center;
  font-weight: light;

  

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    text-align: center;
    flex-flow: column nowrap;

  }

  @media screen and (max-width: 360px) {
    display: flex;
    align-items: center;
    text-align: center;
    height: 20px;

  }
  @media screen and (max-width: 1030px) {
    height: 50px;
    display: flex;
    list-style-type: none;
    align-items: center;
    font-weight: light;
  }
`;

export const NavLinks = styled.button`
  text-decoration: none;
  padding: 12px;
  font-family: "Open Sans";
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  font-size: 15px;
  background-color: transparent;
  outline: none;
  border: 0px;
  cursor: pointer;


&:hover {
    border-bottom: 1px solid rgb(158, 49, 116);
    color: rgb(158, 49, 116);
}

&.${props => props.activeClassName} {
    color: #D2CFCE ;
    font-weight: bold;

  }

  @media screen and (max-width: 1030px) {
    display: flex;
    align-items: center;
    text-align: center;
  }
`;



export const Div2 = styled.div`
  display: flex;
  height: -100px;
  margin-right: 15%;
  margin-left: 70%;
  margin-top: 9.5%;
  // margin-right: 14em;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 1300px) {
    display: flex;
    height: -100px;
    margin-right: 12%;    
    justify-content: flex-end;
    align-items: center;

  }



  @media screen and (max-width: 1030px) {
    display: flex;
    height: -100px;
    margin-right: 4%;
    justify-content: flex-end;
    align-items: center;

  }

  @media screen and (max-width: 1030px) {
    margin-right: 8%;  
    align-items: center;
  
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const NavBurger = styled.nav`
  @media screen and (min-width: 768px) {
    display: none;
  }  
`;

export const NavBurgerDiv = styled.div`
    margin-bottom: 70px;
    margin-left: 50px;
    margin-right: 50px;
    width: auto;

    @media screen and (max-width: 767px) {
    margin-left: 10%;
    // margin-top: 1%;
    width: auto;

    }

    @media screen and (max-width: 475px) {
      margin-left: 5%;
      // margin-top: 1%;
      width: auto;
  
      }
`;

