import React, { Component } from 'react';
import './App.css';
import Layout from "./layout"
import { data } from "./yourdata"
// import scrollTo from "gatsby-plugin-smoothscroll"

// const Card = ({ heading, paragraph, imgUrl, githubLink, websiteLink, linkedInLink }) => {
//   return (
//     <div
//       className="card"
//       style={{
//         backgroundImage:
//           "linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.2)),url(" +
//           imgUrl +
//           ")",
//       }}
//     >
//       <div className="content">
//         <h1 className="header">{heading}</h1>
//         <p className="text">{paragraph}</p>
//         <a
//           href={websiteLink ? websiteLink : "#"}
//           target="_blank"
//           rel="noopener noreferrer"
//           className="btn"
//         >
//           Website
//         </a>

//         {(githubLink !=='none') ?
//           <a
//           href={githubLink ? githubLink : "#"}
//           target="_blank"
//           rel="noopener noreferrer"
//           className="btn"
//         >
//           GitHub
//         </a> :

//         <a
//         href={linkedInLink}
//         target="_blank"
//         rel="noopener noreferrer"
//         className="btn"
//         >
//         LinkedIn
//         </a>
//       }
//       </div>
//     </div>
//   )
// }


export default class App extends Component {
  state = {
    xMain: 0,
    yMain: 0,
    xTrailing: 0,
    yTrailing: 0,
    show: false,
    text: "View Resume"
  };

  handleMouseMove = (e) => {
    const { clientX, clientY } = e;

    this.setState({
      xMain: clientX,
      yMain: clientY,
    }, () => {

      setTimeout(() => {
        this.setState({
          xTrailing: clientX,
          yTrailing: clientY,
        })
      }, 100);
    })
  }

  showhide = () => {
    this.setState({ show: !this.state.show });
  };

  changeText = (text) => {

    this.setState({ text }); 
  } 

  render() {
       const {
        xMain,
        yMain,
        xTrailing,
        yTrailing
      } = this.state;

      

    return (
      <div className='container'
      onMouseMove={e => this.handleMouseMove(e)}>
    <Layout>

  {/* HEADER START */}
  <div className="section" id="home" >
      <div className="container">
        <div className="header-wrapper">
            <h2>
              Hi, I'm {data.name}{" "}
              {/* <span role="img" aria-label="Emoji">
                👋
              </span> */}
            </h2>
{/* 
        <div style={{display:"flex"}}>
            <button onClick={() => scrollTo("#contact")} className="primary-btn">
              Connect With Me
            </button>
            <button onClick={this.showhide} className="primary-btn"> View Resume</button>
        </div> */}


            {/* <div>
            {this.state.show && 
            <div>
              <div className="button-wrap">
              <button className="close-resume-button" onClick={this.showhide}> x </button>
              </div>
              <img src="https://res.cloudinary.com/techhire/image/upload/q_100/v1651273634/Claudia_Mazariegos_Resume_r2liuy.png" className="resume"alt="logo" />
            </div>
            }
          </div> */}

        </div>
      </div>
    </div>
  {/* HEADER END */}

   {/* WORK START */}
   <div className="section" id="work" style={{backgroundColor:''}}>
      <br></br>
      <div className="container">
        <div className="work-wrapper" style={{backgroundColor:''}}>
            {/* <h1>Work</h1> */}

          {/* <div className="grid">
              {data.projects.map(project => (
                <Card
                  key={project.id}
                  heading={project.title}
                  paragraph={project.para}
                  imgUrl={project.imageSrc}
                  githubLink={project.github}
                  linkedInLink={project.linkedin}
                  websiteLink={project.url}
                ></Card>
              ))}
          </div> */}
        </div>
      </div>
    </div>
   {/* WORK END */}

{/* ABOUT ME  START*/}
    <div className="section" id="about" style={{backgroundColor:''}}>
      <div className="container" style={{backgroundColor:''}}>
        <div className="about-section" style={{backgroundColor:''}}>
          <div className="content" style={{backgroundColor:''}}>
            <p className="paragraph" style={{backgroundColor:''}}>
              {/* {data.aboutParaOne} */}
              <br></br>
              <br></br>
              {/* {data.aboutParaTwo} */}
            </p>
          </div>
          <div className="image-wrapper" style={{backgroundColor:''}}>
            {/* <img className="about-me-image" src={data.aboutImage} alt="about"></img> */}
          </div>
        </div>
      </div>
    </div>
{/* ABOUT ME  END*/}

{/* SKILLS START */}
<div className="section" style={{backgroundColor:''}}>
      <div className="container">
        <div className="skills-container" style={{backgroundColor:''}}>
          <h1>Stack & Tools</h1>
          <div className="skills-grid" style={{backgroundColor:''}}>
            {data.skills.map(skill => (
              <div className="skill" key={skill.id} style={{backgroundColor:''}}>
                <img src={skill.img} alt="css"></img>
                <p>{skill.para}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
{/* SKILLS END */}



{/* FOOTER START */}
<div className="section" id="contact" style={{backgroundColor:''}}>
      <div className="container">
        <div className="footer-container" style={{backgroundColor:''}}>
            {/* <h1>Contact</h1> */}
            {/* <h2>{data.contactSubHeading}</h2> */}
          <a className="email-link" href={`mailto:${data.contactEmail}`}>
            {data.contactEmail}
          </a>
          {/* <div className="social-icons1">
            {data.social.map(socialLink => (
              <div className="social-icons" style={{backgroundColor:''}}>
              <a
                href={socialLink.url}
                target="_blank"
                rel="noopener noreferrer"
                key={socialLink.url}
              >
                <img src={socialLink.img} alt="icons"></img>
              </a>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  {/* FOOTER END */}
</Layout>

<div className='cursors'>

<div 
  className='cursor'
  style={{ 
    left: xMain, 
    top: yMain,
  }}
/>


<div 
  className='cursor'
  style={{ 
    left: xTrailing, 
    top: yTrailing,
  }}
/>
</div>
  </div>
    );
  }
}
