
import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import "./mains.scss"
import './App.css';
import { data } from "./yourdata"



import {
  // Nav2,
  NavItem,
  NavLinks,
  Div2,
  NavBurger,
  NavBurgerDiv
} from './NavStyling';


const Layout = ({ children }) => {
  return (
    <>
    <>
    <div className="section">
<div className="social-container" style={{backgroundColor:''}}>
            {data.social.map(socialLink => (
              <div  className="social-icons1"  style={{backgroundColor:''}}>
              <a
                href={socialLink.url}
                target="_blank"
                rel="noopener noreferrer"
                key={socialLink.url}
              >
                <img src={socialLink.img} className="social-images" alt="icons"></img>
              </a>
              </div>
            ))}
</div>
</div>

<Div2 style= {{background:''}}>
            <NavItem style= {{background:''}}>
                    <NavLinks onClick={() => scrollTo("#work")}>
                    Work
                    </NavLinks>
            </NavItem>
   
            <NavItem>
                    <NavLinks onClick={() => scrollTo("#about")}>  
                    About
                    </NavLinks>
            </NavItem>

            <NavItem>
                    <NavLinks onClick={() => scrollTo("#contact")}>
                    Contact
                    </NavLinks>
            </NavItem>
    </Div2>

{/* HAMBURGERRRRR */}
    <>
    <NavBurger role="navigation" style= {{background:''}}>
  <NavBurgerDiv id="menuToggle">
    <input type="checkbox"/>

    <span></span>
    <span></span>
    <span></span>
    
  
    <ul id="menu">
      <button onClick={() => scrollTo("#work")}><li>Work</li></button>
      <button onClick={() => scrollTo("#about")}><li>About</li></button>
      <button onClick={() => scrollTo("#contact")}><li>Contact</li></button>
    </ul>
  </NavBurgerDiv>
</NavBurger>

    </>
    </>

      <main >{children}</main>
    </>
  )
}
export default Layout
